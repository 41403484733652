import { Controller } from '@hotwired/stimulus'
import { Popover } from "bootstrap/dist/js/bootstrap.bundle.js";


export default class extends Controller {
  static targets = [ ]

  connect() {
    new Popover(this.element, {
      trigger: 'focus',
    })
  }
}
