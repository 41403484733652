require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('thredded_imports.js');

import '../plugins/index.js';
import '../components/index.js';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// $(document).on('turbolinks:load', function(){ $.rails.refreshCSRFTokens(); });



import "controllers"

require("trix")
require("@rails/actiontext")